exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-camere-index-jsx": () => import("./../../../src/pages/camere/index.jsx" /* webpackChunkName: "component---src-pages-camere-index-jsx" */),
  "component---src-pages-contatti-index-jsx": () => import("./../../../src/pages/contatti/index.jsx" /* webpackChunkName: "component---src-pages-contatti-index-jsx" */),
  "component---src-pages-dicono-di-noi-index-jsx": () => import("./../../../src/pages/dicono-di-noi/index.jsx" /* webpackChunkName: "component---src-pages-dicono-di-noi-index-jsx" */),
  "component---src-pages-dove-siamo-index-jsx": () => import("./../../../src/pages/dove-siamo/index.jsx" /* webpackChunkName: "component---src-pages-dove-siamo-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-ristorante-jsx": () => import("./../../../src/pages/menu/ristorante.jsx" /* webpackChunkName: "component---src-pages-menu-ristorante-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-ristorante-index-jsx": () => import("./../../../src/pages/ristorante/index.jsx" /* webpackChunkName: "component---src-pages-ristorante-index-jsx" */),
  "component---src-pages-ristorante-menu-index-jsx": () => import("./../../../src/pages/ristorante/menu/index.jsx" /* webpackChunkName: "component---src-pages-ristorante-menu-index-jsx" */),
  "component---src-pages-ristorante-menu-mezza-pensione-e-pensione-completa-index-jsx": () => import("./../../../src/pages/ristorante/menu-mezza-pensione-e-pensione-completa/index.jsx" /* webpackChunkName: "component---src-pages-ristorante-menu-mezza-pensione-e-pensione-completa-index-jsx" */),
  "component---src-pages-scopri-assisi-e-la-verde-umbria-index-jsx": () => import("./../../../src/pages/scopri-assisi-e-la-verde-umbria/index.jsx" /* webpackChunkName: "component---src-pages-scopri-assisi-e-la-verde-umbria-index-jsx" */),
  "component---src-pages-trasparenza-index-jsx": () => import("./../../../src/pages/trasparenza/index.jsx" /* webpackChunkName: "component---src-pages-trasparenza-index-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/blogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */)
}

