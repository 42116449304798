module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-HD425KN4BL","cookieName":"cookie-consent","anonymize":true,"allowAdFeatures":false},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["it","en"],"defaultLanguage":"it","siteUrl":"https://hotelmodernoassisi.com/","i18nextOptions":{"debug":false,"ns":["common"],"defaultNS":"common","interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":","fallbackLng":"en"},"pages":[{"matchPath":"/:lang?/blog/:postSlug","excludeLanguages":["en"],"languages":["it"]},{"matchPath":"/:lang?/(privacy|transparency)","excludeLanguages":["en"],"languages":["it"]},{"matchPath":"/scopri-assisi-e-la-verde-umbria","excludeLanguages":["en"],"languages":["it"]}]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"disableBgImage":true,"linkImagesToOriginal":false,"quality":100,"withWebp":true,"wrapperStyle":"margin-left: 1rem; margin-right: 1rem; max-width: 100%;","maxWidth":650,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
